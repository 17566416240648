<template>
  <p class="s-name">{{solution.name}}</p>
  <p class="s-class">{{solution.class}}</p>
  <p class="s-price">
    <!-- <span>价格：{{goods.productPrice}}</span> -->
  </p>
  <div class="s-service">
    <dl>
      <dd>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{solution.desc}} </dd>
    </dl>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'SolutionName',
  props: {
    goods: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const { target, result } = {
    "target": "操作成功",
    "result":  [
            {
                "id": "001",
                "name": "智慧农业解决方案",
                "class": "传感器",
                "image": " ",
                "desc":"  智慧农业就是将物联网技术运用到传统农业中去，运用传感器和软件通过移动平台或者电脑平台对农业生产进行控制，使传统农业更具有“智慧”。除了精准感知、控制与决策管理外，从广泛意义上讲，智慧农业还包括农业电子商务、食品溯源防伪、农业休闲旅游、农业信息服务等方面的内容。",
            }, 
        ]
}
    return solution;
    // // 提供给后台的四项数据 (没登录)
    // const provinceCode = ref('110000')
    // const cityCode = ref('119900')
    // const countyCode = ref('110101')
    // const fullLocation = ref('北京市 市辖区 东城区')
    // // 取出用户收货地址中默认的地址给四个数据赋值 (已登录)
    // if (props.goods.userAddresses) {
    //   const defaultAddresss = props.goods.userAddresses.find(item => item.isDefualt === 1)
    //   if (defaultAddresss) {
    //     provinceCode.value = defaultAddresss.provinceCode
    //     cityCode.value = defaultAddresss.cityCode
    //     countyCode.value = defaultAddresss.countyCode
    //     fullLocation.value = defaultAddresss.fullLocation
    //   }
    // }
    // // 城市选中事件处理函数
    // const changeCity = (result) => {
    //   provinceCode.value = result.provinceCode
    //   cityCode.value = result.cityCode
    //   countyCode.value = result.countyCode
    //   fullLocation.value = result.fullLocation
    // }
    // return { fullLocation, changeCity }
  }
}
</script>

<style lang="less" scoped>
.base-city {
  vertical-align: middle;
}
.s-name {
  font-size: 28px;
  font-weight: bold;
  padding: 10px 0 10px 50px;
}
.s-class {
  color: #999;
  margin-top: 10px;
  padding: 0 0 0 50px;
}
.s-price {
  margin-top: 10px;
  padding: 0 0 0 50px;
  span {
    &::before {
      content: "¥";
      font-size: 14px;
    }
    &:first-child {
      color: @priceColor;
      margin-right: 10px;
      font-size: 16px;
    }
    // &:last-child {
    //   color: #999;
    //   text-decoration: line-through;
    //   font-size: 16px;
    // }
  }
}
.s-service {
  background: #f5f5f5;
  margin-left: 50px;
  line-height: 30px;
  padding: 20px 10px 0 10px;
  margin-top: 10px;
  dl {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    dt {
      width: 50px;
      color: #999;
    }
    dd {
      color: #666;
      &:last-child {
        span {
          margin-right: 10px;
          &::before {
            content: "•";
            color: @baseColor;
            margin-right: 2px;
          }
        }
        a {
          color: @baseColor;
        }
      }
    }
  }
}
</style>
